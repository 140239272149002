.image-generator {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}

.input-wrapper {
  position: relative;
  margin-bottom: 16px;
  width: 100%;
}

.input-icon {
  position: absolute;
  top: 12px;
  left: 12px;
  color: #999;
}

.prompt-input {
  width: 100%;
  max-width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 2.5rem;
  border: 2px solid #d1d1d1;
  border-radius: 10px;
  font-size: 1rem;
  resize: none;
  min-height: 80px;
  max-height: 200px;
  height: 80px; /* Initial height */
  overflow-y: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  transition: border-color 0.3s, box-shadow 0.3s, height 0.3s;
  box-sizing: border-box;
}

.prompt-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 227, 0.3);
}

.generate-button, .download-button {
  background-color: #000000;
  color: #ffffff;
  padding: 0.75rem 1.25rem;
  border: 2px solid #000000;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
  width: auto;
  min-width: 150px;
}

.generate-button:hover, .download-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.spinner {
  animation: spin 1s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  color: #ff3b30;
  margin-top: 16px;
  font-size: 0.9rem;
}

.generated-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%; /* Default width for mobile devices */
  max-width: 600px; /* Maximum width on larger screens */
  margin: 0 auto;
}

.generated-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* For screens larger than 768px (typical tablet size) */
@media (min-width: 768px) {
  .generated-image {
    width: 60%;
  }
}

/* For screens larger than 1200px */
@media (min-width: 1200px) {
  .generated-image {
    width: 60%;
  }
}

.download-button {
  margin-top: 16px;
}

.download-button svg {
  margin-right: 8px;
}

.controls {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
}

.aspect-ratio-select {
  padding: 0.6rem 1rem;
  border: 2px solid #000000;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  background-color: #ffffff;
  cursor: pointer;
  margin-top: 4px;
  height: 100%;
}

.aspect-ratio-select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.aspect-ratio-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 16px;
}

.aspect-ratio-container label {
  margin-bottom: 4px;
  font-weight: 500;
}

.aspect-ratio-description {
  font-size: 0.8rem;
  color: #666;
  margin-top: 4px;
}

.controls {
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
}

.generate-button {
  align-self: flex-end;
}

.generate-button-container {
  display: flex;
  align-items: flex-end;
}

.generate-button {
  margin-top: 0;
  margin-bottom: 0;
}

.aspect-ratio-and-button {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-top: 16px;
}

.aspect-ratio-container {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}

.generate-button-container {
  display: flex;
  align-items: flex-end;
}

.generate-button {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 16px;
}
