@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
  padding: 1.25%;
  box-sizing: border-box;
}

.container {
  background-color: white;
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
}

.icon-container {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.snowflake-icon {
  font-size: 3rem;
  color: #000000;
}

h1 {
  margin-bottom: 0.5rem;
  font-size: 2rem;
}

p {
  color: #666;
  margin-bottom: 2rem;
  font-size: 1rem;
}

h3 {
  text-align: left;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 100%;
}

@media (max-width: 768px) {
  .button-grid {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}

button {
  background-color: #ffffff;
  border: 2px solid #000000;
  color: #000000;
  padding: 0.6rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 50px;
}

button:hover {
  background-color: #000000;
  color: #ffffff;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.input-wrapper {
  position: relative;
  margin-bottom: 16px;
  width: 100%;
}

.input-icon {
  position: absolute;
  top: 12px;
  left: 12px;
  color: #999;
}

.prompt-input {
  width: 100%;
  max-width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 2.5rem;
  border: 2px solid #d1d1d1;
  border-radius: 10px;
  font-size: 1rem;
  resize: none;
  min-height: 80px;
  max-height: 200px;
  overflow-y: auto;
  font-family: 'Poppins', sans-serif;
  transition: border-color 0.3s, box-shadow 0.3s;
  box-sizing: border-box;
}

.prompt-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 227, 0.3);
}

.refresh-button {
  background-color: #000000;
  color: #ffffff;
  border: 2px solid #000000;
}

.refresh-button:hover {
  background-color: #000000;
  color: #ffffff;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
